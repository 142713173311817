<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import basicTable from "@/components/listings/basic-table";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";
import baseTable from "@/components/listings/base-table";
import InputModal from "@/components/modals/input-modal";
import InputGroup from "@/components/forms/input-group";
// import ImageFile from '@/components/buttons/image-file';
/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        baseTable,
        basicTable,
        InputModal,
        InputGroup,
        // ImageFile
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `Agent ${modeName}`,
            modeName,
            items: [
                {
                    text: "Agent"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            details_fields: [
                { key: "registration_number", "label": "Registration Number" },
                { key: "with_agency", "label": "Has Agency" },
                { key: "agency.agency_name", "label": "Agency Name" },
                { key: "member.first_name", "label": "Agent's First Name" },
                { key: "member.last_name", "label": "Agent's Last Name" },
                { key: "member.id", "label": "Member's Info" },
            ],
            details_data: {},

            //points
            points_fields: [
                // { key: "id", "label": "ID" },
                { key: "amount", "label": "Amount" },
                { key: "remark", "label": "Remark" },
                { key: "action_by", "label": "Added By" },
                { key: "created_at", "label": "Date" },
            ],
            points_items: [],
            points_page: 1,
            points_per_page: 20,
            points_loading: true,
            points_total: 0,
            current_points: 0,
            payout_data: {
                "remark": "",
                "amount": 0
            },
            payout_options: [
                { item: true, name: 'Add' },
                { item: false, name: 'Deduct' }
            ],
            payout_selected:true,
            errors: {},
            
            agent_proof: []
        };
    },
    computed: {

    },
    async mounted() {
        await this.loadDetails();
        await this.loadPointsHistory(1);
    },
    methods: {
        async loadDetails() {
            await axios.get(`${process.env.VUE_APP_APIURL}/agent/${this.id}`, {
                headers: authHeader(),
            }).then((e) => {
                if (e.status == 200 && e.data.code == 'success') {
                    this.details_data = e.data.data;
                    this.agent_proof = this.details_data.agent_pic;
                } else if (e.status == 200 && e.data.code == 'permission_denied') {
                    this.$refs.messageModal.showModal("Permission Denied");
                } 
            })
        }, 
        async changePage(e) {
            await this.loadPointsHistory(e);
        },
        async loadPointsHistory(page) {
            if (this.details_data.member == undefined) {
                this.points_loading = false;
                return; 
            }
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/agent-points/${this.details_data.member.id}`, {
                headers: authHeader(),
                params: {
                    "page": page,
                    "length": this.points_per_page
                }
            }).catch(e => {
                console.log(e);
            });
            if (result.status == 200 && result.data.code == 'success') {
                const { data } = result.data; 
                let {data: table_data } = data;
                table_data.map((e) => {
                    if (parseInt(e.amount) < 0) {
                        e._rowVariant = 'danger'
                    }
                    return e;
                });
                this.points_items = table_data;
                this.points_loading = false;
                this.points_total = data.total;
                this.current_points = data.current_points;
            }  else if (result.status == 200 && result.data.code == 'permission_denied') {
                this.points_loading = false;
            } else if (result.status == 204) {
                this.points_items = [];
                this.points_loading = false;
                this.points_total = 0;
                this.current_points = 0;
            } 
        },
        openModal() {
            this.errors = {};
            this.payout_data.amount = 0;
            this.payout_data.remark = '';
            this.payout_selected = true;
            this.$refs.InputModal.showModal();
        },
        async addPayout() {
            this.errors = {};
            this.payout_data.amount = this.payout_selected ? Math.abs(this.payout_data.amount) : -Math.abs(this.payout_data.amount);
            await axios.post(`${process.env.VUE_APP_APIURL}/add-agent-points/${this.details_data.member.id}`, this.payout_data, {
                headers: authHeader(),
            }).then(async (e) => {
                if (e.status == 200) {
                    this.$refs.InputModal.closeModal();
                    this.agency_payout_page = 1;
                    await this.loadPointsHistory(this.agency_payout_page);
                }
            }).catch((e) => {
                if (e.response.status == 422) {
                    this.errors = e.response.data.errors;
                    if (e.response.data.errors.member != undefined) {
                        this.$refs.messageModal.showModal('Invalid Member');
                    }
                }
                console.log(e);
            });
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <InputModal title="Edit Payout" ref="InputModal">
            <template #inputField>
                <!-- <div class="row"> -->
                    <div class="col-12 mb-2">
                        <span class="subTitle-font">Current Payout Amount:</span> {{ current_points }}
                    </div>
                    
                    <div class="col-12">
                        <InputGroup id="amount" name="amount" type="number" v-model.trim="payout_data.amount" displayName="Amount" :error="errors.amount"/>
                    </div>
                    <div class="col-12">
                        <b-form-radio-group v-model="payout_selected" :options="payout_options" class="mb-3" value-field="item" text-field="name">
                            
                        </b-form-radio-group>
                    </div>

                    <div class="col-12">
                        <InputGroup id="remark" name="remark" type="text" v-model.trim="payout_data.remark" displayName="Remark" :error="errors.remark"/>
                    </div>

                    <div class="col-12">
                        <button class="btn btn-orange" @click="addPayout">
                            Confirm
                        </button>
                    </div>
                <!-- </div> -->
            </template>
        </InputModal>

        <div class="card" style="border-radius: 15px;">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-12">
                        <button class="btn btn-orange mb-2" @click="openModal">
                            <i class="mdi mdi-plus mr-2"></i> Edit Payout
                        </button>
                    </div>
                </div>

                <baseTable :fields="details_fields" :items="details_data" heading="Agent Details">
                    <template #is_verified>
                        <b-badge :variant="parseInt(details_data.is_verified) == 1 ? 'success' : 'danger' ">{{ parseInt(details_data.is_verified) == 1 ? 'Verified' : "Not Verified" }}</b-badge>
                    </template>
                    <template #member.id>
                        <!-- {{details_data["member"]["id"] }} -->
                        <router-link
                            class="mr-2 text-primary"
                            :to="{ name: 'Member View', params: { id: details_data.member.id } }"
                            v-if="details_data.member != undefined">
                                <u>View Member's Detail</u>
                        </router-link>
                    </template>
                </baseTable>


                <div class="row mt-4">
                    <!-- <template v-if="agent_proof.length > 0">
                        <div class="col-12 col-sm-6 col-md-4 mt-2" v-for="doc, index in agent_proof" :key="doc.id">
                            <ImageFile :img_url="doc.image" :file_name="'proof_doc-' + (parseInt(index) + 1)" :file_id="parseInt(doc.id)" />
                        </div>
                    </template> -->

                    <div class="col-12">
                        <strong>
                            <p style="font-size:16px; font-weight: 900;" class="mb-0 mt-2">Current points: {{ current_points }}p</p>
                        </strong>
                    </div>
                   
                    <div class="col-12">
                        <basicTable :items="points_items" :fields="points_fields" :perPage="points_per_page" @page-click="changePage" :isBusy="points_loading" :totalRows="points_total" class="mt-2">
                        </basicTable>
                    </div>
                </div>
            </div>
        </div>
</Layout></template>